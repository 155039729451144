import React from "react";
import HeadingTwo from "../common/HeadingTwo";
import Paragraph from "../common/Paragraph";

const PhysicalDataCenterSoultions = () => {
  return (
    <div id={"1"}>
      <img
        id="physical-data"
        src={require("../../images/detailed/pds.jpg")}
        alt="pdcs"
      />
      <Paragraph>
        Partnering with Unboggled gives you a guarantee for competitiveness,
        continuity and uptime for your business operations. Our flexibility,
        proficiency and forward-looking data center solutions ensure you can
        choose the ideal option that works best for you
      </Paragraph>
      <div id={"1-1"}>
        <HeadingTwo>Physical Security, Fire and Life Safety</HeadingTwo>
        <Paragraph>
          Today, businesses are reliant on efficient, reliable and secure data
          centers to keep operations going. Unboggled uses state-of-the-art
          security features and procedures, and assists in project management of
          fire protection systems modeled by our partner. Upgrading your
          security and fire/life safety infrastructure has never been easier.
        </Paragraph>
      </div>

      <div id={"1-2"}>
        <HeadingTwo>Low Voltage Cabling and Wire Management</HeadingTwo>
        <Paragraph>
          Unboggled offers a wide-range of industrial-grade cable management
          solutions that you can rely on. As telecommunication is heavily
          reliant on low voltage cabling and fiber optics, Unboggled follows
          manufacturer bend radius guidelines, installs vertical and horizontal
          wire management devices, ladder rack, cable tray and uses several
          structured cabling techniques for easy identification and
          troubleshooting.
        </Paragraph>
        <Paragraph>
          Low voltage physical infrastructure is a critical piece to keeping
          businesses operating successfully but fiber and Ethernet cables can
          quickly become a messy risk without a plan and proper cable management
          techniques. Low Voltage cabling does not have to be an eyesore; in
          fact, it can be a work of art! We combine industry best practices
          (BICSI, TIA &amp; NFPA) with our cable management experience to
          produce an aesthetically pleasing, functional and safe cabling project
          for our customers. Proper cabling and labeling techniques reduce the
          risk of cable misidentification which could lead to a human error
          caused outage. Let our team organize, label and document each cable
          within your environment to improve convenience and accuracy.
        </Paragraph>
      </div>

      <div id={"1-3"} className="py-3">
        <img
          id="technology"
          src={require("../../images/detailed/bestcabling.png")}
          alt="dct"
        />
      </div>

      <div id={"1-4"}>
        <HeadingTwo>Physical Infrastructure Deployment</HeadingTwo>
        <Paragraph>
          Unboggled’s unique approach to infrastructure deployment has
          eliminated business and technology pressures for many of our clients.
          Our infrastructure management system allows clients to optimize data
          center floor and rack space. The deployment process can be done all at
          once, or in a phased approach design allowing for growth.
        </Paragraph>
      </div>

      <div id={"1-5"}>
        <HeadingTwo>Racking and Stacking</HeadingTwo>
        <Paragraph>
          We don’t just rack all your equipment randomly. Unboggled will work
          with you to come up with cabinet elevation and rack diagrams to ensure
          your equipment is racked the way you need it to be. Network
          connectivity and power cabling pathways are identified to create
          cabinets and racks which allow ample access to all equipment. Timely
          and accurate installation of your equipment minimizes downtime. Our
          seasoned experts and project managers ensure your equipment
          installation is done proficiently the first time.
        </Paragraph>
      </div>
    </div>
  );
};

export default PhysicalDataCenterSoultions;
