import React from 'react';
import HeadingTwo from '../common/HeadingTwo';
import Paragraph from '../common/Paragraph';

const DataCenterConsulting = () => {
  return (
    <div className="py-8" id={"3"}>
      <img
        id="data-center"
        src={require("../../images/detailed/dcc.png")}
        alt="dcc"
        className="relative"
      />
      <Paragraph>
        The economics of building, upgrading or colocating IT infrastructure can
        be daunting for most IT staff and Real Estate advisors. At Unboggled, we
        approach data center consultation from a lifecycle perspective, offering
        power and cooling assessments, site assessments and innovative designs
        for your business needs. We have a top-down approach and achieve a
        granular level of detail you will not find elsewhere.
      </Paragraph>
      <div id={"3-1"}>
        <HeadingTwo>Data Center Assessment</HeadingTwo>
        <Paragraph>
          Unsure of costs or design strategies for your data center facility?
          Unboggled’s expert engineering team provides site audits and performs
          on-site evaluations of your data center facilities, can provide
          benchmark and post implementation data, and assist with equipment and
          room design evaluations for strategic planning.
        </Paragraph>
      </div>
      <div id={"3-2"}>
        <HeadingTwo>
          Business Continuity Disaster Recovery Planning and Testing
        </HeadingTwo>
        <Paragraph>
          Does your business continuity plan take into account contingencies?
          Partnering with Unboggled safeguards the reliability and availability
          of mission critical applications and data in your corporate data
          center, while minimizing risks of unplanned outages.
        </Paragraph>
        <Paragraph>
          The list of threats which can bring your business to an abrupt stop
          continues to grow. Major equipment failures, cyberattacks and natural
          disasters top the list of threats that require a “not if but when”
          approach. Creating a unique business continuity plan will minimize
          downtime, establish a state of order, and help restore operations in a
          timely manner. Each business requires a distinct approach and plan. We
          will work with your company to collect information on the following
          topics in order to create a formal plan:
        </Paragraph>

        <ul className="pl-5 list-disc">
          <li>Connectivity and Network resiliency</li>
          <li>Critical Asset and Application Identification</li>
          <li>Data Backup / Replication Methodology</li>
          <li>Spare parts list</li>
          <li>Building maps</li>
          <li>Disaster Recovery Office Space</li>
          <li>Communication Channels</li>
          <li>Chain of Command</li>
          <li>Test plans, blackout testing and live failover</li>
        </ul>

        <Paragraph>
          Our plans focus on the details that make your business operate. In
          order to know a plan will work, it must be tested. We can assist on
          performing live or dry runs of your existing or proposed plan.
        </Paragraph>
      </div>

      <div id={"3-3"}>
        <HeadingTwo>Data Center Maintenance Planning</HeadingTwo>
        <Paragraph>
          Skilled operations teams are necessary to facilitate maintenance and
          lifecycle strategies for data centers. Unboggled’s predictive and
          preventive maintenance plans have helped our clients eliminate the
          risk of down time and allow data centers to run efficiently. Unboggled
          saves you time and money while giving you risk adverse maintenance
          options.
        </Paragraph>
      </div>

      <div id={"3-4"}>
        <HeadingTwo>Colocation Evaluations and Comparison</HeadingTwo>
        <Paragraph>
          Unboggled assists customers in finding the colocation data center that
          suits them best. We compile the critical data so you can accurately
          compare, contrast and pick the best colocation space for your needs.
          Our rigid vendor and data center evaluation checklist is adapted to
          the individual customer requirements each and every time. We have a
          global database of providers which gives you the ease of mind in
          planning and allows for focus to be placed on the actual migration.
        </Paragraph>
      </div>
    </div>
  );
};

export default DataCenterConsulting;
