import React, { useState, useEffect } from 'react';
import { Link } from "gatsby";
import Banner from '../components/detailedOfferings/Banner';
import SideNav from '../components/detailedOfferings/SideNav';
import Soultions from '../components/detailedOfferings/PhysicalDataCenterSoultions';
import Services from '../components/detailedOfferings/DataCenterTechnologyServices';
import Consulting from '../components/detailedOfferings/DataCenterConsulting';
import Container from '../components/common/Container';
import MobileNav from '../components/detailedOfferings/MobileNav';
import Hamburger from 'hamburger-react';
import AppLayout from "../layout/AppLayout";
const detailedOfferings = (props) => {
  // // eslint-disable-next-line no-debugger
  // debugger
  console.log(props)
  if ((props.location || {}).hash)
  useEffect( () => {

  })
  const [isOpen, setOpen] = useState(false);
  return (
    <AppLayout
    routes={[
      { name: "Data Center Physical Solutions", href: "#physical-data" },
      { name: "Technology Services", href: "#technology" },
      { name: "Data Center Consulting", href: "#data-center" },
      { name: "Contact Us", href: "../contact" },
    ]}
  >
    <div className='relative'>
      <Banner />
      <Container className='sm:px-4 lg:px-0'>
        <div className='flex justify-between'>
          <div className='fixed z-20 text-white bg-black top-10 left-10 sm:hidden'>
            {!isOpen && <Hamburger toggled={isOpen} toggle={setOpen} />}
          </div>

          {isOpen && <MobileNav isOpen={isOpen} setOpen={setOpen} />}
          <SideNav />

          <div className='w-10/12 mx-auto leading-5 sm:w-8/12'>
            <Soultions />
            <Services />
            <Consulting />
          </div>
        </div>
      </Container>
    </div>
    <div className="pb-5 mt-5" id="contact">
          <Link to="/contact">
            <button className="block px-5 py-2 mx-auto duration-100 border border-black rounded-md hover:bg-black hover:text-white">
              Contact Us
            </button>
          </Link>
        </div>
    </AppLayout>
  );
};

export default detailedOfferings;

{
  /* <div>
        <h1 className='text-3xl uppcase'>Physical Data Center Soultions</h1>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Distinctio
          veritatis, nobis, atque vero perspiciatis laboriosam fuga cupiditate
          natus consectetur obcaecati officiis aliquam libero animi impedit
          corporis illo quaerat quos reprehenderit.
        </p>
      </div> */
}
