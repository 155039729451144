import React from 'react';
import propTypes from 'prop-types';

const Paragraph = ({ children }) => {
  return <p className='sm:text-sm my-3'>{children}</p>;
};

export default Paragraph;

Paragraph.propTypes = {
  children: propTypes.node.isRequired,
};
