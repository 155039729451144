import React from 'react';

const Banner = () => {
  return (
    <div className='background-img sm:h-64 h-48 flex items-center justify-center '>
      <h1 className='text-white font-bold uppercase md:text-3xl text-2xl'>
        Detailed Offerings
      </h1>
    </div>
  );
};

export default Banner;
