import React from 'react';
import { Link } from 'react-scroll';

const SideNav = () => {
  return (
    <div className='relative hidden w-3/12 sm:block test'>
      <div className='sticky mx-auto border-2 border-black rounded lg:w-9/12 top-20'>
        <ul>
          <li className='pl-2 font-bold border-b-2 cursor-pointer hover:underline'>
            <Link
              activeClass='active'
              to='1'
              spy={true}
              smooth={true}
              duration={200}
              offset={-4}
            >
              Physical Data Center Soultions
            </Link>
          </li>

          <ul className='text-xs'>
            <li className='pl-3 font-bold border-b-2 cursor-pointer hover:underline text-gray-custom'>
              <Link
                activeClass='active'
                to='1-1'
                spy={true}
                smooth={true}
                duration={200}
                offset={-4}
              >
                Physical Security, Fire and Life Saftey
              </Link>
            </li>

            <li className='pl-3 font-bold border-b-2 cursor-pointer hover:underline text-gray-custom'>
              <Link
                activeClass='active'
                to='1-2'
                spy={true}
                smooth={true}
                duration={200}
                offset={-4}
              >
                Low Voltage Cabling and Wire Management
              </Link>
            </li>

            <li className='pl-3 font-bold border-b-2 cursor-pointer hover:underline text-gray-custom'>
              <Link
                activeClass='active'
                to='1-3'
                spy={true}
                smooth={true}
                duration={200}
                offset={-14}
              >
                Our Best Cabling Practices
              </Link>
            </li>

            <li className='pl-3 font-bold border-b-2 cursor-pointer hover:underline text-gray-custom'>
              <Link
                activeClass='active'
                to='1-4'
                spy={true}
                smooth={true}
                duration={200}
                offset={-4}
              >
                Physical Infrastructure Deployment
              </Link>
            </li>

            <li className='pl-3 font-bold border-b-2 cursor-pointer hover:underline text-gray-custom'>
              <Link
                activeClass='active'
                to='1-5'
                spy={true}
                smooth={true}
                duration={200}
                offset={-4}
              >
                Racking and Stacking
              </Link>
            </li>
          </ul>

          <li className='pl-2 font-bold border-b-2 cursor-pointer hover:underline'>
            <Link
              activeClass='active'
              to='2'
              spy={true}
              smooth={true}
              duration={200}
              offset={-4}
            >
              Data Center Technology Services
            </Link>
          </li>

          <ul className='text-xs'>
            <li className='pl-3 font-bold border-b-2 cursor-pointer hover:underline text-gray-custom'>
              <Link
                activeClass='active'
                to='2-1'
                spy={true}
                smooth={true}
                duration={200}
                offset={-4}
              >
                Technology Project Management
              </Link>
            </li>

            <li className='pl-3 font-bold border-b-2 cursor-pointer hover:underline text-gray-custom'>
              <Link
                activeClass='active'
                to='2-2'
                spy={true}
                smooth={true}
                duration={200}
                offset={-4}
              >
                Data Center Migration / Equipment Relocation / Colocation
                Relocation
              </Link>
            </li>

            <li className='pl-3 font-bold border-b-2 cursor-pointer hover:underline text-gray-custom'>
              <Link
                activeClass='active'
                to='2-3'
                spy={true}
                smooth={true}
                duration={200}
                offset={-4}
              >
                Equiptment Decommissioning and Removal
              </Link>
            </li>

            <li className='pl-3 font-bold border-b-2 cursor-pointer hover:underline text-gray-custom'>
              <Link
                activeClass='active'
                to='2-4'
                spy={true}
                smooth={true}
                duration={200}
                offset={-4}
              >
                Floorplan and Cabinet Elevation Diagrams
              </Link>
            </li>

            <li className='pl-3 font-bold border-b-2 cursor-pointer hover:underline text-gray-custom'>
              <Link
                activeClass='active'
                to='2-5'
                spy={true}
                smooth={true}
                duration={200}
                offset={-4}
              >
                Inventory and Asset Management
              </Link>
            </li>
          </ul>

          <li className='pl-2 font-bold border-b-2 cursor-pointer hover:underline'>
            <Link
              activeClass='active'
              to='3'
              spy={true}
              smooth={true}
              duration={200}
              offset={-4}
            >
              Data Center Consulting
            </Link>
          </li>

          <ul className='text-xs'>
            <li className='pl-3 font-bold border-b-2 cursor-pointer hover:underline text-gray-custom'>
              <Link
                activeClass='active'
                to='3-1'
                spy={true}
                smooth={true}
                duration={200}
                offset={-4}
              >
                Data Center Assessment
              </Link>
            </li>
            <li className='pl-3 font-bold border-b-2 cursor-pointer hover:underline text-gray-custom'>
              <Link
                activeClass='active'
                to='3-2'
                spy={true}
                smooth={true}
                duration={200}
                offset={-4}
              >
              Business Continuity Disaster Recovery Planning and Testing
              </Link>
            </li>

            <li className='pl-3 font-bold border-b-2 cursor-pointer hover:underline text-gray-custom'>
              <Link
                activeClass='active'
                to='3-3'
                spy={true}
                smooth={true}
                duration={200}
                offset={-4}
              >
                Data Center Maintenance Planning
              </Link>
            </li>
            <li className='pl-3 font-bold border-b-2 cursor-pointer hover:underline text-gray-custom'>
              <Link
                activeClass='active'
                to='3-4'
                spy={true}
                smooth={true}
                duration={200}
                offset={-4}
              >
                Colocation Evaluations and Comparison
              </Link>
            </li>
          </ul>
        </ul>
      </div>
    </div>
  );
};

export default SideNav;
