import Hamburger from 'hamburger-react';
import React from 'react';
import { Link } from 'react-scroll';
import PropTypes from 'prop-types';

const MobileNav = ({ isOpen, setOpen }) => {
  return (
    <div className="absolute top-0 w-8/12 h-full bg-white border-4  sm:hidden">
      <div className="relative h-full">
        <div className="sticky w-9/12 mx-auto top-70 ">
          <div className="absolute z-20 top-neg60 left-neg30">
            <Hamburger toggled={isOpen} toggle={setOpen} />
          </div>
          <ul className="border border-black">
            <li className="pl-2 font-bold border-b-2 cursor-pointer">
              <Link
                activeClass="active"
                to="1"
                spy={true}
                smooth={true}
                duration={700}
                offset={-4}
              >
                Physical Data Center Soultions
              </Link>
            </li>

            <ul className="text-xs">
              <li className="pl-3 border-b-2 cursor-pointer">
                <Link
                  activeClass="active"
                  to="1-1"
                  spy={true}
                  smooth={true}
                  duration={700}
                  offset={-4}
                >
                  Physical Security, Fire and Life Saftey
                </Link>
              </li>

              <li className="pl-3 border-b-2 cursor-pointer">
                <Link
                  activeClass="active"
                  to="1-2"
                  spy={true}
                  smooth={true}
                  duration={700}
                  offset={-4}
                >
                  Low Voltage Cabling and Wire Management
                </Link>
              </li>

              <li className="pl-3 border-b-2 cursor-pointer">
                <Link
                  activeClass="active"
                  to="1-3"
                  spy={true}
                  smooth={true}
                  duration={700}
                  offset={-14}
                >
                  Our Best Cabling Practices
                </Link>
              </li>

              <li className="pl-3 border-b-2 cursor-pointer">
                <Link
                  activeClass="active"
                  to="1-4"
                  spy={true}
                  smooth={true}
                  duration={700}
                  offset={-4}
                >
                  Physical Infrastructure Deployment
                </Link>
              </li>

              <li className="pl-3 border-b-2 cursor-pointer">
                <Link
                  activeClass="active"
                  to="1-5"
                  spy={true}
                  smooth={true}
                  duration={700}
                  offset={-4}
                >
                  Racking and Stacking
                </Link>
              </li>
            </ul>

            <li className="pl-2 font-bold border-b-2 cursor-pointer">
              <Link
                activeClass="active"
                to="2"
                spy={true}
                smooth={true}
                duration={700}
                offset={-4}
              >
                Data Center Technology Services
              </Link>
            </li>

            <ul className="text-xs">
              <li className="pl-3 border-b-2 cursor-pointer">
                <Link
                  activeClass="active"
                  to="2-1"
                  spy={true}
                  smooth={true}
                  duration={700}
                  offset={-4}
                >
                  Technology Project Management
                </Link>
              </li>

              <li className="pl-3 border-b-2 cursor-pointer">
                <Link
                  activeClass="active"
                  to="2-2"
                  spy={true}
                  smooth={true}
                  duration={700}
                  offset={-4}
                >
                  Data Center Migration / Equipment Relocation / Colocation
                  Relocation
                </Link>
              </li>

              <li className="pl-3 border-b-2 cursor-pointer">
                <Link
                  activeClass="active"
                  to="2-3"
                  spy={true}
                  smooth={true}
                  duration={700}
                  offset={-4}
                >
                  Equiptment Decommissioning and Removal
                </Link>
              </li>

              <li className="pl-3 border-b-2 cursor-pointer">
                <Link
                  activeClass="active"
                  to="2-4"
                  spy={true}
                  smooth={true}
                  duration={700}
                  offset={-4}
                >
                  Floorplan and Cabinet Elevation Diagrams
                </Link>
              </li>

              <li className="pl-3 border-b-2 cursor-pointer">
                <Link
                  activeClass="active"
                  to="2-5"
                  spy={true}
                  smooth={true}
                  duration={700}
                  offset={-4}
                >
                  Inventory and Asset Management
                </Link>
              </li>
            </ul>

            <li className="pl-2 font-bold border-b-2 cursor-pointer">
              <Link
                activeClass="active"
                to="3"
                spy={true}
                smooth={true}
                duration={700}
                offset={-4}
              >
                Data Center Consulting
              </Link>
            </li>

            <ul className="text-xs">
              <li className="pl-3 border-b-2 cursor-pointer">
                <Link
                  activeClass="active"
                  to="3-1"
                  spy={true}
                  smooth={true}
                  duration={700}
                  offset={-4}
                >
                  Data Center Assessment
                </Link>
              </li>
              <li className="pl-3 border-b-2 cursor-pointer">
                <Link
                  activeClass="active"
                  to="3-2"
                  spy={true}
                  smooth={true}
                  duration={700}
                  offset={-4}
                >
                  Data Center Maintenance Planning
                </Link>
              </li>
              <li className="pl-3 border-b-2 cursor-pointer">
                <Link
                  activeClass="active"
                  to="3-3"
                  spy={true}
                  smooth={true}
                  duration={700}
                  offset={-4}
                >
                  Colocation Evaluations and Comparison
                </Link>
              </li>
            </ul>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MobileNav;

MobileNav.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.bool.isRequired,
};
