import React from 'react';
import propTypes from 'prop-types';
import { Element } from 'react-scroll';

const HeadingTwo = ({ children, id }) => {
  return (
    <Element id={id}>
      <h2 className='text-xl font-bold mb-6 leading-6 my-8'>{children}</h2>
    </Element>
  );
};

export default HeadingTwo;

HeadingTwo.propTypes = {
  children: propTypes.node.isRequired,
  id: propTypes.string.isRequired,
};
