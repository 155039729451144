import React from 'react';
import HeadingTwo from '../common/HeadingTwo';
import Paragraph from '../common/Paragraph';

const DataCenterTechnologyServices = () => {
  return (
    <div className="py-8" id={"2"}>
      <img
        id="technology"
        src={require("../../images/detailed/dct.jpg")}
        alt="dct"
      />
      <Paragraph>
        Maintenance and optimization of your data center is crucial to keep
        mission critical operations uninterrupted. Our Service Team of Experts
        deliver on services that ensure your data center is fully functional and
        caters to your relocation, expansion or decommissioning needs.
      </Paragraph>
      <div id={"2-1"}>
        <HeadingTwo>Technology Project Management</HeadingTwo>
        <Paragraph>
          Having expert support and supervision is essential to ensuring
          technology centered projects are finished in time and within cost
          limits. Let Unboggled worry about hiring the best from our team of
          experts to deploy technologies for your business needs.
        </Paragraph>
        <Paragraph>
          Technology project management requires specialized expertise in order
          to meet the time, quality and budget set forth. The planning and
          process of technology projects can differ from general/normal project
          management and require special attention to detail. Unboggled can work
          to coordinate and collaborate among your staff and construction
          project team members to ensure the technology portion of your project
          does not become an afterthought. The technological portion of your
          project is our entire project!
        </Paragraph>
        <Paragraph>
          Technological project examples include physical security, fire and
          life safety, audio and visual, wireless networks, network
          distribution, equipment builds, automation, access controls,
          structured cabling and more. Technology is part of nearly every
          project; let us know how we can be a part of yours!
        </Paragraph>
      </div>
      <div id={"2-2"}>
        <HeadingTwo>
          Data Center Migration / Equipment Relocation / Colocation Relocation
        </HeadingTwo>
        <Paragraph>
          Who enjoys moving? Let Unboggled get your gear from point A to point
          B. With Unboggled as your partner, you will no longer need to worry
          about your data center migration. Our experienced Project Planning
          Team uses industry-standard methodologies and state-of-the-art
          transport mechanisms to facilitate safe and efficient data center
          migration.
        </Paragraph>
        <Paragraph>
          <a
            className="text-blue-600 hover:underline visited:text-purple-600"
            href="http://unboggled.com/Uploads/6MigrationPractices.pdf"
            target="_blank"
            rel="noreferrer"
          >
            More Details Here
          </a>{" "}
        </Paragraph>
      </div>

      <div id={"2-3"}>
        <HeadingTwo>Equipment Decommissioning and Removal</HeadingTwo>
        <Paragraph>
          At Unboggled, we have an experienced team ready to undertake site
          decommissioning and removal services. These services encompass the
          entire equipment removal spectrum through inventory cataloging, asset
          verification and onsite data erasure, among others, remaining on- site
          throughout the entire removal process.
        </Paragraph>
      </div>

      <div id={"2-4"}>
        <HeadingTwo>Floorplan and Cabinet Elevation Diagrams</HeadingTwo>
        <Paragraph>
          The complexity of data centers can be tasking for internal teams.
          Unboggled has unmatched experience in creating strategic solutions
          through floorplan and cabinet elevation diagrams that maximize space
          and capacity, lower power and cooling costs, and forecast for growth.
          We are proud to partner with software providers to help our customers
          gain network visibility for management and documentation of their data
          centers.
        </Paragraph>
      </div>

      <div id={"2-5"}>
        <HeadingTwo>Inventory and Asset Management</HeadingTwo>
        <Paragraph>
          Are you aware of every asset in your enterprise data center? The
          framework designed by our Expert Team at Unboggled allows you to
          perform periodic audits and process analysis to keep track of all
          physical, software and virtual assets, ensuring workflows are
          uninterrupted.
        </Paragraph>
      </div>
    </div>
  );
};

export default DataCenterTechnologyServices;
